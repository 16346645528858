import "https://cdn.jsdelivr.net/npm/@mediapipe/camera_utils/camera_utils.js";
import { FilesetResolver, ObjectDetector } from "@mediapipe/tasks-vision";

let video = document.getElementById("webcam");


async function updateFrames() {
  if (objectDetector) {
    const results = await objectDetector.detectForVideo(
      video,
      performance.now()
    );
    if (results) {
      onResults(results);
    }
  }
}

async function createObjectDetector() {
  const filesetResolver = await FilesetResolver.forVisionTasks(
    "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision/wasm"
  );

  const objectDetector = await ObjectDetector.createFromOptions(
    filesetResolver,
    {
      baseOptions: {
        modelAssetPath:
          "https://storage.googleapis.com/mediapipe-models/object_detector/efficientdet_lite0/float32/latest/efficientdet_lite0.tflite",
      },
      runningMode: "VIDEO",
      maxResults: 1,
    }
  );

  return objectDetector;
}

let docVideo = document.getElementById('videoBox');

let videoPlayed = false;

function onResults(results) {
  if (results.detections.length > 0) {
    results.detections.forEach((detection) => {
      const objectName = detection.categories[0].categoryName;
      const objectConf = detection.categories[0].score;
      
      // console.log(detection.categories[0]);
      if (objectName === "refrigerator" && objectConf >= 0.6) {
        // document.querySelector('.classificacao').textContent = `Confiança: ${objectConf.toFixed(2)}`;
        if (!videoPlayed) {
          document.querySelector('.rewarded').classList.remove('d-none');
          docVideo.play();
          videoPlayed = true;
          docVideo.onended = function(e) {
          docVideo.pause();
          }
        }
      }
    });
  }
}

const objectDetector = await createObjectDetector();

async function startCamera() {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: "environment" }, 
      audio: false,
    });

    video.srcObject = stream;
    video.play();

    const deviceCamera = new Camera(video, {
      onFrame: updateFrames,
      facingMode: "environment",
    });
    deviceCamera.start();
    
  } catch (error) {
    if (error.name === 'NotAllowedError') {
      alert("Permissão para usar a câmera foi negada. Por favor, habilite o acesso à câmera.");
    } else if (error.name === 'NotFoundError') {
      alert("Nenhuma câmera foi encontrada no dispositivo.");
    } else {
      alert("Ocorreu um erro ao acessar a câmera: " + error.message);
    }
  }
}

startCamera();